$.fn.isAfter = function(sel) {
    return this.prevAll(sel).length !== 0;
}
$.fn.isBefore = function(sel) {
    return this.nextAll(sel).length !== 0;
}
$('[data-expand]').off('click').on('click', function(){
	$('body').toggleClass('expand');
	if( $('body').hasClass('expand') ){
		$.get('/menu/show');
		$('.icon > i', this).removeClass('fa-chevron-circle-right').addClass('fa-chevron-circle-left');
	}else{
		$.get('/menu/hide');
		$('.icon > i', this).removeClass('fa-chevron-circle-left').addClass('fa-chevron-circle-right');
	}
});
var keysPressed = [];
$(document).on("keyup keydown", function(e) {
    switch(e.type) {
        case "keydown" :
            keysPressed.push(e.keyCode);
            break;
        case "keyup" :
        	keysPressed = [];
            break;
    }
});
function isKeyPressed(code) {
    return keysPressed.indexOf(code) >= 0;
}
var sites = $('.site');
var lastClicked = false;
$('.bookings tbody td:not(.occupied)').off('click').on('click', function(e, shifted){
	var clicked = $(this);
	var site = sites.eq(e.target.cellIndex - 3).data('site');
	var date = $(this).parents('tr').eq(0).data('date');
	var c_date, index, c_index, s_index, e_index;

	if( !shifted && isKeyPressed(16) ){
		var closest, distance = 0, direction;
		if( !lastClicked ){
			$('.checked').each(function(){
				c_date = $(this).closest('tr').eq(0).data('date');
				if( distance == 0 || Math.abs( date - c_date ) < distance ){
					distance = ( Math.abs( date - c_date ) );
					direction = ( date - c_date > 0 ) ? true : false;
					closest = $(this);
					c_index = closest.index() - 3;
				}
			});
		}else{
			c_date = lastClicked.closest('tr').data('date');
			distance = ( Math.abs( date - c_date ) );
			direction = ( date - c_date > 0 ) ? true : false;
			closest = lastClicked;
			c_index = closest.index() - 3;
		}
		index = clicked.index() - 3;
		if( index > c_index ){
			s_index = c_index;
			e_index = index;
		}else{
			s_index = index;
			e_index = c_index;
		}
		if( closest ){
			$('.bookings tr').each(function(){
				var r_date = $(this).data('date');
				var columns = $('td', this);
				for( i = s_index; i <= e_index; i++ ){
					var column = columns.eq(i);
					if( !$(column).is(clicked) && !$(column).is(closest) ){
						if( !direction ){
							if( r_date >= date && r_date <= c_date ) column.not('.occupied').trigger('click', [true]);
						}else{
							if( r_date <= date && r_date >= c_date ) column.not('.occupied').trigger('click', [true]);
						}
					}
				}
			});
		}
	}

	$(this).toggleClass('checked');

	if($(this).is('.checked[data-setup-dismantle]')) {
		$('#notifications').prepend('<div class="alert-box alert" data-alert>Setup or dismantle on this date<a href="#" class="close"><i class="fa fa-times"></i></a></div>').foundation();
	}

	// For Testing Display;
	if( $('#'+date+site, '#booking').length > 0 ){
		$('#'+date+site, '#booking').remove();
	}else{
		$('#booking').prepend('<div id="'+date+site+'"><input type="hidden" name="date[]" value="'+date+'"/><input type="hidden" name="site[]" value="'+site+'"/></div>');
	}

	// Disable buttons when not needed.
	if( $('.bookings tbody td.checked').length > 0 ){
		$('#booking button').attr('disabled', false);
	}else{
		$('#booking button').attr('disabled', true);
	}

	if( !shifted && clicked.hasClass('checked') ) lastClicked = clicked;
});
if( !$('.bookings').data('tracking') ){/*
	var updateInterval = setInterval(function(){
		$.getJSON('/booking/recent', function(data){
			console.log( data );
			if( data ){
				booking_update( data );
			}
		});
	}, 10000);*/
	function booking_update( updates ){
		var removed = [];
		for( i = 0; i < updates.length; i++ ){
			var update = updates[i];
			var x = update['x'];
			var y = update['y'];
			var content = update['content'];
			var notice = update['notice'];
			var type = update['type'];
			var reservation = update['reservation'];
			var row = $('.bookings [data-date="'+y+'"]');
			if( !removed[reservation] ){
				$('.bookings [data-reservation="'+reservation+'"]').closest('td').removeClass('occupied');
				$('.bookings [data-reservation="'+reservation+'"]').remove();
				removed[reservation] = true;
			}
			var cell = $('td', row).eq(x);
			if( type == 'alert' ){
				cell.removeClass('occupied').html('');
			}else{
				cell.addClass('occupied').html(content);
			}
			if( notice != '' ) $('#notifications').prepend('<div class="alert-box '+type+'" data-alert>'+notice+'<a href="#" class="close"><i class="fa fa-times"></i></a></div>').foundation();
		}
	}
	$('.bookings').data('tracking', true).attr('data-tracking', true);
}
$('#booking').off('click', '[data-clear-selection]').on('click', '[data-clear-selection]', function(){
	$('.bookings tbody td.checked').trigger('click');
});
$('#booking').off('click', 'button[type="submit"]').on('click', 'button[type="submit"]', function(e){
	e.preventDefault();
	var form = $(this).closest('form');
	if( $(this).is('[data-redirect]')){
		form.append( $('<input type="hidden" name="redirect" value="true" />'));
	}
	form.submit();
});
$('[data-copy]').off('click').on('click', function(){
	$('input[name*="street_"]').each(function(){
		var to = $(this).attr('name').replace('street', 'postal');
		var value = $(this).val();
		$('input[name="' + to + '"]').val(value);
	});
});
$('.reservation').off('change', 'select[name$="[site_id]"]').on('change', 'select[name$="[site_id]"]', function(){
	var parent = $(this).closest('.booking');
	var site = $(this).val();
	var select = $('#dates_' + site);
	var select_start = $('#dates_' + site + ' option:first-child').val();
	var select_end = $('#dates_' + site + ' option:last-child').val();
	var swap = parent.data('booking');

	var start = ( $('[data-select="start"]', parent).data('selected') ) ? $('[data-select="start"]', parent).data('selected') : $('option:first-child', select).val();
	var end = ( $('[data-select="end"]', parent).data('selected') ) ? $('[data-select="end"]', parent).data('selected') : $('option:last-child', select).val();
	var difference = ( end - start ) * 1;

	if( start < select_start ){
		start = select_start;
		end = ( start  * 1 ) + ( difference * 1 );
	}
	if( end > select_end ){
		end = select_end;
	}

	$('[data-select="start"]', parent).html('').append( select.clone(true).attr('name', 'booking['+swap+'][start]').removeAttr('id').val(start) );
	$('[data-select="end"]', parent).html('').append( select.clone(true).attr('name', 'booking['+swap+'][end]').removeAttr('id').val(end) );
});
$('.reservation').off('click', '[data-add-client]').on('click', '[data-add-client]', function(){
	var parent = $(this).closest('.reservation');
	var booking = $('.templates > .booking').clone(true);
	var time = $.now();
	booking.html( booking.html().replace( /\#id/g, time ) ).data('booking', time).attr('data-booking', time);
	$('.count > span', booking).html( $('.booking', parent).length + 1 );
	$('[data-bookings]', parent).append( booking );
	$('.reservation select[name$="[site_id]"]').trigger('change');
}).on('click', '[data-remove]', function(){
	var parent = $(this).closest('.reservation');
	var booking = $(this).closest('.booking');
	booking.remove();
	$('.booking', parent).each(function(index){
		$('.count > span', this).html( ( index + 1 ) );
	});
});
$('.reservation').off('change', '[data-result]').on('change', '[data-result]', function(){
	var parent = $(this).closest('.booking');
	var contacts = $('[data-contacts]', parent);
	contacts.html('<p class="text-center"><i class="fa fa-spin fa-cog fa-3x"></i><br/>Loading...</p>');
	var contact = contacts.data('contacts');
	$.get('/client/' + $(this).val() + '/contacts/booking', function(data){
		if( data ){
			data = data.replace( /\#id/g, contact );
			contacts.html(data);
		}
	});
	var conflicts = $('[data-conflicts]', parent);
	var conflict = conflicts.data('conflicts');
	var start_date = $('[data-select="start"] > select', parent);
	var end_date = $('[data-select="end"] > select', parent);
	$.post('/reservation/conflicts/' + $(this).val(), { 'start': start_date.val(), 'end': end_date.val() }, function(data){
		if( data ){
			data = data.replace( /\#id/g, conflict );
			//var modal = $('<div class="reveal-modal small" id="conflict-detected" data-reveal><div class="modal callout alert"><h5>Warning!</h5><p>There are conflicting bookings for this family, please remove them if you do not wish to double book them!</p><br/>' + data + '</div></div>');
			//$('body').append( modal );
			//$('#conflict-detected').foundation( 'reveal', 'open' );
			conflicts.html( data );
			$('.conflicts, p, a', conflicts).css( 'background', '#da3116' ).css( 'color', '#FFFFFF' );
			$('h2', conflicts).removeClass( 'alert' ).css( 'font-size', '1.5rem' ).css( 'line-height', '1' ).css( 'margin', '0' ).css( 'font-weight', 'bold' ).css( 'background', '#da3116' ).css( 'color', '#FFFFFF' );
		}
	});
});
$('.reservation').off('click', 'button[type="submit"]').on('click', 'button[type="submit"]', function(e){
	e.preventDefault();
	var form = $(this).closest('form');
	if( $(this).is('[data-redirect]') ){
		form.append( $('<input type="hidden" name="redirectPath" value="' + $(this).data('redirect') + '"/>') );
	}
	form.submit();
})
$('.client').off('click', '[data-add-contact]').on('click', '[data-add-contact]', function(){
	var parent = $(this).closest('.client');
	var contacts = $('[data-contacts]', parent);
	var contact = $('.templates .contact').clone(true);
	contacts.append(contact);
}).on('click', '[data-remove]', function(){
	var parent = $(this).closest('.contact');
	parent.remove();
});

$(document).off('keyup', '[data-search]').on('keyup', '[data-search]', function(){
	var modal = $(this).closest('.reveal-modal');
	var token = $.now();
	var term = $(this);
	var parent = $(this).closest('[data-searching]');
	var result = $('[data-result]', parent);

	// Check if there is already a request and cancel it
	var request = term.data('request');
	if(request && request.abort) {
		request.abort();
		term.data('request', null);
	}

	if( $('.results', parent).length == 0 ){
		var results = $('<div class="results"></div>');
		results.on('click', '[data-option]', function(){
			if( $('#name', modal).length && $('#name', modal).val() == '' ) $('#name', modal).val( $(this).data('facade') ).trigger('blur');
			result.val( $(this).data('value') );
			term.val( $(this).data('facade') );
			result.trigger('change');
			$(this).closest('.results').remove();
		}).on('click', '[data-search-close]', function(){
			results.remove();
			term.val('').focus();
		});
		parent.append( results );
	}
	$('.results', parent).attr('data-token', token).html('<p class="text-center"><i class="fa fa-spin fa-cog fa-3x"></i><br/>Searching...</p>');
	var url = $(this).data('search');
	var search = $(this).val() ? $(this).val() : '*';
	term.data('request', $.get(url + '/' + search, function(data){
		if( data ){
			$('[data-token="'+token+'"]', parent).html( data );
		}
	}));
});
$(document).off('click', '[data-modal]').on('click', '[data-modal]', function(e){
	e.preventDefault();

	var button = $(this);
	var url = button.data('modal');
	if( !url ) return false;
	var track = button.data('track') ? ( ( button.data('track') != '' ) ? button.data('track') : url ) : false;
	var name = button.data('name') ? button.data('name') : 'modal' + $.now();
	var size = button.data('size') ? button.data('size') : 'medium';


	//Load over current modal, if exists
	if( $(this).parents('.reveal-modal').length > 0 ){
		$(this).parents('.reveal-modal').eq(0).nextAll('.tooltip').remove();
		//$(document).foundation('tooltip', 'reflow');
		$(this).parents('.reveal-modal').eq(0).attr('id', name).attr('class', 'reveal-modal ' + size).html('<div class="modal"><p class="text-center"><i class="fa fa-spin fa-cog fa-4x"></i><br/>Loading...</p></div>');
	}else{
		var modal = $('<div class="reveal-modal ' + size + '" id="' + name + '" data-reveal><div class="modal"><p class="text-center"><i class="fa fa-spin fa-cog fa-4x"></i><br/>Loading...</p></div></div>');
		$('body').append(modal);
	}

	$('#' + name).foundation('reveal', 'open')
	$.get( url, function( data ){
		if( data ){
			$('#' + name).html( data );
			if( track ) window.history.pushState({"html":data, "pageTitle":$('title').html()}, "", track);
			//debugger;
			/*$('#' + name).find('.notes').tinymce({
				toolbar: 'bold italic underline',
				menubar: false,
				elementpath: false,
				statusbar: false
			});*/
		}
	});
	$('#' + name).on('click', '[data-close]', function(e){
		e.preventDefault();
		$('#' + name).nextAll('.tooltip').remove();
		$('#' + name).foundation('reveal', 'close').remove();
		//$(document).foundation('tooltip', 'reflow');
	});
	$('.reveal-modal-bg').on('click', function(){
		$('#' + name).nextAll('.tooltip').remove();
		$('#' + name).foundation('reveal', 'close').remove();
		$(this).remove();
	});
});
$(document).off('submit', '[data-modal-form]').on('submit', '[data-modal-form]', function(e){
	e.preventDefault();

	var form = $(this);
	var url = form.attr('action');
	if( !url ) return false;
	var track = form.data('track') ? ( ( form.data('track') != '' ) ? form.data('track') : url ) : false;
	var name = form.data('name') ? form.data('name') : 'modal' + $.now();
	var size = form.data('size') ? form.data('size') : 'medium';


	//Load over current modal, if exists
	if( $(this).parents('.reveal-modal').length > 0 ){
		$(this).parents('.reveal-modal').eq(0).attr('id', name).attr('class', 'reveal-modal ' + size).html('<div class="modal"><p class="text-center"><i class="fa fa-spin fa-cog fa-4x"></i><br/>Loading...</p></div>');
	}else{
		var modal = $('<div class="reveal-modal ' + size + '" id="' + name + '" data-reveal><div class="modal"><p class="text-center"><i class="fa fa-spin fa-cog fa-4x"></i><br/>Loading...</p></div></div>');
		$('body').append(modal);
	}
	$('#' + name).foundation('reveal', 'open');
	$.post( url, form.serialize(), function( data ){
		if( data ){
			$('#' + name).html( data );
			if( track ) window.history.pushState({"html":data, "pageTitle":$('title').html()}, "", track);
			/*$('#' + name).find('.notes').tinymce({
				toolbar: 'bold italic underline',
				menubar: false,
				elementpath: false,
				statusbar: false
			});*/
		}
	});
	$('#' + name).on('click', '[data-close]', function(e){
		e.preventDefault;
		$('#' + name).foundation('reveal', 'close').remove();
	});
	$('.reveal-modal-bg').on('click', function(){
		$('#' + name).foundation('reveal', 'close').remove();
		$(this).remove();
	});
});
$('.bookings #snap').off('click').on('click', function(e){
	e.preventDefault();
	var href = $(this).attr('href');
	var parent = $(this).closest('.bookings');
	var sticky = $('.sticky-wrap', parent);
	var table = $('table.sticky', sticky);
	if( href == '#holding' ){
		sticky.scrollLeft(table.width() - sticky.width() + 128);
		$(this).attr('href', '#booking');
	}else{
		sticky.scrollLeft(0);
		$(this).attr('href', '#holding');
	}
	var table = $('.bookings .sticky-wrap');
	if( table.scrollLeft() >= ( $('table.sticky', table).width() - table.width() ) ){
		$(this).attr('href', '#booking').html($(this).data('booking'));
	}else{
		$(this).attr('href', '#holding').html($(this).data('holding'));
	}
	$(this).blur();
});
$('[data-show-payments]').off('click').on('click', function(){
	var parent = $(this).closest('.booking');
	var payments = $('[data-payments]', parent);
	var triggers = $('[data-show-payments]', parent);
	if( payments.is(':visible') ){
		payments.hide();
		triggers.each(function(){
			$(this).html($(this).data('hidden'));
		});
	}else{
		payments.show();
		triggers.each(function(){
			$(this).html($(this).data('visible'));
		});
	}
});
var func = {
	'addRowToTable': addRowToTable
}
$('[data-submit]').off('click').on('click', function(){
	var form = $(this).closest('[data-form]');
	var action = form.data('form');
	var callback = form.data('callback');
	var clean = true;
	$('[data-mandatory]', form).each(function(){
		if( $(this).val() == '' || $(this).val() == undefined || $(this).val() == null ){
			clean = false;
			$(this).css('border-color', '#f04124');
		}
	});
	if( clean ){
		$.post(action, form.prop('elements', $('*', form).andSelf().get()).serializeArray(), function(data){
			//console.log( data );
			if( data.error === false ){
				func[callback](data, form);
			}
		}, 'json');
	}
});
$(document).off('change', '[data-other]').on('change', '[data-other]', function(){
	if( $(this).val() == $(this).data('other') ){
		$(this).attr('data-restore-me','');
		var addAttrs = $(this).data('add-attributes');
		var name = $(this).attr('name');
		var input = $('<input type="text" name="'+name+'" data-remove-me '+addAttrs+' />');
		$(this).wrap('<div class="replacement"></div>');
		$(this).after(input).after('<button class="button tiny alert other-cancel" data-other-cancel>&times;</button>').hide();
		input.focus();
	}
});
$(document).off('click', '[data-other-cancel]').on('click', '[data-other-cancel]', function(){
	if( $(this).parent().is('.replacement') ){
		var parent = $(this).closest('.replacement');
		$('[data-remove-me]', parent).remove();
		var original = $('[data-restore-me]', parent);
		original.val($('option:first-child', original).val()).show();
		$(this).unwrap().remove();
		original.trigger('change');
	}
});
$(document).off('change blur keyup', '[data-email]').on('change blur keyup', '[data-email]', function(){
	var parent = $(this).closest('[data-payments]');
	var email = $(this).val();
	$('[data-resend]', parent).each(function(){
		$(this).attr('href', '/payment/resend/' + $(this).data('resend') + '/' + email );
	});
});
$('[data-resend]').off('click').on('click', function(e){
	console.log('Its hitting here;');
	var parent = $(this).closest('[data-payments]');
	var email = $('[name$="[client][email]"]:last', parent).val();
	if( confirm( "This will resend the receipt for this payment to: " + email + "\n\nAre you sure you wish to proceed?" ) ){
		return true;
	}else{
		e.preventDefault();
		return false;
	}
});
if( typeof addRowToTable !== 'function' ){
	function addRowToTable(data, form){
		var parent = form.closest('[data-payments]');
		var table = $('table', parent);
		$('input[type!="radio"], input[type!="checkbox"], textarea', form).each(function(){
			if( $(this).attr('type') != 'hidden' ){
				$(this).val('');
			}
		});
		$('[data-empty]', table).remove();
		table.append( $('<tr><td>' + data.date + '</td><td>' + data.type + '</td><td>' + data.notes + '</td><td>' + data.user + '</td><td>' + data.amount + '</td><td><a href="' + data.link + '" target="_blank" class="button full tiny secondary no-margin">' + data.resend + '</a></td></tr>') );
	}
}
$('[data-amounts]').off('change', 'input[type="radio"]').on('change', 'input[type="radio"]', function(){
	var parent = $(this).closest('[data-amounts]');
	var total = 0;
	$('input[type="radio"]:checked', parent).each(function(){
		console.log( $(this).val() * 1 );
		total += $(this).val()*1;
	});
	$('#amount').val(total);
});
if( $('#start').length && $('#end').length ){
	$('#start-button').click(function(){
		endDate.hide();
		startDate.show();
	});
	$('#end-button').click(function(){
		startDate.hide();
		endDate.show();
	});
	var startDate = $('#start').fdatepicker({
		format: 'dd-mm-yyyy'
	}).on('changeDate', function(e){
		if(e.date.valueOf() > endDate.date.valueOf()){
			var newDate = new Date(e.date);
			newDate.setDate(newDate.getDate() + 1);
			endDate.update(newDate);
		}
		startDate.hide();
		$('#end')[0].focus();
	}).data('datepicker');
	var endDate = $('#end').fdatepicker({
		format: 'dd-mm-yyyy',
		onRender: function(date){
			if( $('#end').attr('data-include') == 'true' ){
				console.log('Its true!');
				return date.valueOf() < startDate.date.valueOf() ? 'disabled' : '';
			}else{
				console.log('Its false!');
				return date.valueOf() <= startDate.date.valueOf() ? 'disabled' : '';
			}
		}
	}).on('changeDate', function(e){
		endDate.hide();
	}).data('datepicker');
}
if( $('#now').length ){
	$('#now-button').click(function(){
		nowDate.show();
	});
	var nowDate = $('#now').fdatepicker({
		format: 'dd-mm-yyyy'
	}).on('changeDate', function(e){
		nowDate.hide();
	}).data('datepicker');
}
$('input[name="custom_pricing"]').off('change').on('change', function(){
	if( $(this).is(':checked') ){
		var disabled = null;
	}else{
		var disabled = 'disabled';
	}
	$('input', $('.custom-prices')).each(function(){
		$(this).attr('disabled', disabled);
	});
});
$('[data-show-table]').off('click').on('click', function(){
	var table = $('[data-table="'+$(this).data('show-table')+'"]');
	if( table.is(':visible') ){
		$(this).html( $(this).data('hidden') );
		table.hide();
	}else{
		$(this).html( $(this).data('visible') );
		table.show();
	}
});
$('button[type="submit"][data-set]').off('click').on('click', function(e){
	e.preventDefault();
	var form = $(this).closest('form');
	var field = $(this).data('set');
	var value = $(this).data('value');
	$('input[name="'+field+'"]').val(value);
	form.submit();
});
$('[data-select-recipients]').off('click').on('click', function(e){
	$('input[type="checkbox"]', '[data-recipients]').attr('checked', true);
});
$('[data-send-email]').on('click', function(){
	var parent = $(this).closest('[data-form]');
	$(this).attr('href', 'mailto:' + $('[data-email]', parent).val());
	return true;
});
$('[data-print-modal]').on('click', function(){
	var parent = $(this).closest('.reveal-modal');
	var windowobj = window.open("", "PrintWindow", "width=1920,height=1080,top=50,left=50,toolbars=no,scrollbars=no,status=no,resizable=yes");
	windowobj.document.writeln('<!DOCTYPE html>');
	windowobj.document.writeln('<html><head><title></title>');
	windowobj.document.writeln('<meta name="viewport" content="width=device-width, initial-scale=1, maximum-scale=1, user-scalable=no">');
	windowobj.document.writeln('<link rel="stylesheet" type="text/css" href="/css/app.css">');
	windowobj.document.writeln('</head><body>');
	windowobj.document.write(parent.html().replace('<textarea','<div').replace('textarea>','div>'));
	windowobj.document.writeln('<script src="/js/app.js"></script>');
	windowobj.document.writeln('</body></html>');
	windowobj.document.close();
	setTimeout(function(){
	    windowobj.focus();
	    windowobj.print();
	    windowobj.close();
	},1000);
});
$('[data-autofill]').on('click', function(){
	var form;
	if( $('input[name="start"]').length > 0 ) form = $('input[name="start"]').closest('form');
	if( typeof form == 'undefined' ) form = $('input[name="end"]').closest('form');
	if( $('input[name="start"]').length > 0 ) $('input[name="start"]').val($(this).data('start'));
	if( $('input[name="end"]').length > 0 ) $('input[name="end"]').val($(this).data('end'));
	form.submit();
});
/*$(function(){
	function debounce(func, wait, immediate) {
	 var timeout;
	 return function() {
	  var context = this, args = arguments;
	  var later = function() {
	   timeout = null;
	   if (!immediate) func.apply(context, args);
	  };
	  var callNow = immediate && !timeout;
	  clearTimeout(timeout);
	  timeout = setTimeout(later, wait);
	  if (callNow) func.apply(context, args);
	 };
	};

	var logoutAfterDelay = debounce(function() {
	 window.location = '/auth/expired';
	}, 20*60*1000);

	$(document).on('click', logoutAfterDelay).on('keyup', logoutAfterDelay);
	logoutAfterDelay();
});*/


$(function(){
	$('#incfont').click(function(){
		$('*').each(function(){
			//var current = parseFloat( $(this).css('font-size') );
			$(this).css('font-size', '110% !important');
		});
	});
	$('#decfont').click(function(){
		$('*').each(function(){
			//var current = parseFloat( $(this).css('font-size') );
			$(this).css('font-size', '90% !important');
		});
	});
	$('.reservation select[name$="[site_id]"]').trigger('change');
	$('#booking button').each(function(){
		if( $('.bookings tbody td.checked').length > 0 ){
			$(this).attr('disabled', false);
		}else{
			$(this).attr('disabled', true);
		}
	});
	$('.spectrum').spectrum({
		preferredFormat: 'hex',
		showPalette: true,
		togglePaletteOnly: false,
		togglePaletteMoreText: 'More&nbsp;&nbsp;<i class="fa fa-chevron-circle-right"></i>',
		togglePaletteLessText: 'Less&nbsp;&nbsp;<i class="fa fa-chevron-circle-left"></i>',
		chooseText: 'Select&nbsp;&nbsp;<i class="fa fa-check-circle"></i>',
		cancelText: '<i class="fa fa-times-circle"></i>&nbsp;&nbsp;Cancel',
		palette: [
	        ["#000",	"#444",		"#666",		"#999",		"#ccc",		"#eee",		"#f3f3f3",	"#fff"],
	        ["#f00",	"#f90",		"#ff0",		"#0f0",		"#0ff",		"#00f",		"#90f",		"#f0f"],
	        ["#f4cccc",	"#fce5cd",	"#fff2cc",	"#d9ead3",	"#d0e0e3",	"#cfe2f3",	"#d9d2e9",	"#ead1dc"],
	        ["#ea9999",	"#f9cb9c",	"#ffe599",	"#b6d7a8",	"#a2c4c9",	"#9fc5e8",	"#b4a7d6",	"#d5a6bd"],
	        ["#e06666",	"#f6b26b",	"#ffd966",	"#93c47d",	"#76a5af",	"#6fa8dc",	"#8e7cc3",	"#c27ba0"],
	        ["#c00",	"#e69138",	"#f1c232",	"#6aa84f",	"#45818e",	"#3d85c6",	"#674ea7",	"#a64d79"],
	        ["#900",	"#b45f06",	"#bf9000",	"#38761d",	"#134f5c",	"#0b5394",	"#351c75",	"#741b47"],
	        ["#600",	"#783f04",	"#7f6000",	"#274e13",	"#0c343d",	"#073763",	"#20124d",	"#4c1130"]
	    ]
	});
	$('.spectrum').each(function(){
		$(this).css('border-color', 'red');
	});
	$('.bookings .sticky-wrap').on('scroll',function(){
		var snap = $('.bookings #snap');
		if( $(this).scrollLeft() >= ( $('table.sticky', this).width() - $(this).width() ) ){
			snap.attr('href', '#booking').html(snap.data('booking'));
		}else{
			snap.attr('href', '#holding').html(snap.data('holding'));
		}
	});
});